/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  box-sizing: border-box;
}

html, body, #root{
  height:100%;
  padding:0;
  margin:0;
}

a{
  color:#eba400 !important;
  font-weight: 600;
}




